import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout, Helmet, Section, Container } from 'components'

const HistoryPage = () => (
	<Layout className='history-page'>
		<Helmet
			title='Nomade, gestion immobilière'
			description='Notre mission, assurer la qualité de vie de nos locataires, dans immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire.'
			url='/a-propos'
		/>
		<Section>
			<Container>
				<h1>Historique de l’entreprise</h1>
				{/* <div className="photo">
          <StaticQuery
            query={graphql`
              query {
                placeholderImage: file(relativePath: { eq: "history1.jpg" }) {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={data => (
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            )}
          />
        </div> */}
				<p>	
<b>En 1989</b>, Yves Fiset décide de se lancer en gestion d’immeubles. Se concentrant sur les immeubles résidentiels au départ, l’entreprise <b>avait plus de 400 logements</b> en gestion après 3 ans. Il a ensuite ajouté des immeubles commerciaux à son portefeuille de gestion. L’entreprise opérait alors des immeubles dans la région de Québec et Trois-Rivières.
</p>
				{/* <p>
          En <b>1996</b>, l’entreprise est incorporée sous le nom d’Impact
          Gestion Immobilière. À ce moment-là, <b>plus de 1 000 logements et 150 000 pieds carrés d’espaces
            commerciaux et à bureaux </b>sont sous gestion.
        </p>
        <p>
          En <b>2000</b>, plusieurs immeubles plus âgés sont vendus afin de se
          concentrer sur les immeubles plus récents et de plus haut de gamme
          tout en misant sur le marché de Québec métropolitain.
        </p>
        <p>
          Depuis <b>2005</b>, Impact Gestion Immobilière, en plus de faire la
          gestion d’ immeubles, devient promoteur de nouvelles constructions
          résidentielles, commerciales et à bureaux.
        </p>
        <p>
          En <b>2011</b>, l'arrivée de Keven Fiset et Christine Auger comme
          gestionnaire a apporté un vent nouveau à l'entreprise eta stimulé
          l’intérêt pour le développement et l’acquisition de nouveaux projets.
        </p>
        <p>
          En <b>2017</b>, Christine Auger et Keven Fiset se joignent comme associés dans l’entreprise. Ils ont pu démontrer d’excellentes qualités de gestionnaire et d’entrepreneur au fil des 6 années au sein de la société ayant chacun leur champs d’expertise étant la gestion des immeubles commerciaux pour Mme Auger et la gestion des immeubles résidentiels pour M. Fiset.
        </p> */}
				<p>
					<b>En 2019</b>, suite à une forte croissance dans ses deux secteurs d’activités, l’entreprise a été scindé dans le but de séparer la gestion d’immeubles résidentiels et commerciaux. Keven Fiset a créé la compagnie Nomade Gestion immobilière afin de prendre en charge la gestion du parc immobilier résidentiel, domaine dans lequel il s’était spécialisé au cours des 10 années précédentes. L’entreprise opère maintenant en tant que promoteur immobilier et gestionnaire de <b>719 logements</b>, tous situés dans la ville de Québec.
				</p>
				<p>
          <b>En 2023</b>, Simon Boivin, qui était alors employé de l’entreprise depuis quelques années, s’est joint à titre de co-actionnaire dans l’entreprise.
        </p>
				<p>
					Nomade Gestion Immobilière étant actionnaire dans tous les immeubles dont il a la gestion a comme{' '}
					<b>mission</b> d’assurer la <b>qualité de vie</b> de ses locataires en offrant des{' '}
					<b>espaces locatifs novateurs</b> et un <b>service-client</b> hors pair.
				</p>
			</Container>
		</Section>
	</Layout>
)

export default HistoryPage
